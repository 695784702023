<template>
  <el-dialog :title="isEdit ? '编辑' : '新增'" size="80%" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="130px" :rules="rules">
      <!-- <el-form-item label="是否微服务：">
        <el-radio-group v-model="form.microService">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否容器部署：">
        <el-radio-group v-model="form.dockerDeploy">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="后端框架：">
        <el-select v-model="form.backFrameworkId" clearable class="inputWidth mr20" @change="backFrameworkChange">
          <el-option v-for="item in endList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="后端框架版本：">
        <el-select v-model="form.backFrameworkVersionId" clearable class="inputWidth mr20" @change="backFrameworkVersionChange">
          <el-option v-for="item in versionList" :key="item.id" :label="item.backFrameworkVersion" :value="item.backFrameworkVersionId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签：" prop="labelId">
        <el-select v-model="form.labelId" clearable class="inputWidth mr20" @change="labelChange">
          <el-option v-for="(item) in labelList" :key="item.id" :value="item.id" :label="item.label"></el-option>
        </el-select>
        <el-button type="primary" @click="addLabel()">新增</el-button>
      </el-form-item>
      <el-form-item label="Name：" prop="name">
        <el-input v-model="form.name" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="Group：" prop="groupId">
        <el-input v-model="form.groupId" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="Artifact：" prop="artifactId">
        <el-input v-model="form.artifactId" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="类型：" prop="type">
        <el-select v-model="form.type" clearable class="inputWidth mr20">
          <el-option v-for="(item, index) in typeList" :key="index" :value="item.name" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="作用域：" prop="scope">
        <el-select v-model="form.scope" clearable class="inputWidth mr20">
          <el-option v-for="(item, index) in scopeList" :key="index" :value="item.name" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Description：">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.description" class="inputWidth" />
      </el-form-item>
      <el-form-item label="工程名称：" prop="gitlabProjectName">
        <el-input v-model="form.gitlabProjectName" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="工程地址：" prop="gitlabProjectUrl">
        <el-input v-model="form.gitlabProjectUrl" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="代码分支：" prop="branch">
        <el-input v-model="form.branch" class="inputWidth"></el-input>
      </el-form-item>
      <!-- <common-layout class="flex-1 flex-column h100">
        <template #header>
          <el-form inline>
            <el-form-item>
              <el-button type="primary" @click="addTable">新增</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table ref="table" :data="form.versions" border highlight-current-row height="200px">
            <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>

            <el-table-column label="版本" align="center" class="tableInput">
              <template slot-scope="scope">
                <el-form-item :prop="'versions.'+scope.$index+'.version'" :rules="rules.version" label-width="0" style="margin-bottom: 0;">
                  <el-input v-model="scope.row.version"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center">
              <template slot-scope="scope">
                <el-form-item label-width="0" style="margin-bottom: 0;">
                  <el-input v-model="scope.row.versionDescription"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="delTable(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </common-layout> -->
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" :append-to-body="true" size="85%" direction="rtl" @close="getLabel()">
      <lableMgt></lableMgt>
    </el-drawer>
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/relyMgt";
import frameworkApi from "@/api/devops/framework";
import commonApi from "@/api/devops/common";
import lableMgt from "../../common/labelMgt.vue";
export default {
  components: { lableMgt },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    thirdPart: { type: Number, default: 0 },
    detail: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    show(val) {
      val ? (this.getLabel(), this.getFrameAll()) : false;
    },
    detail(val) {
      this.isEdit = !_.isEmpty(val);
      this.form = this.isEdit
        ? val
        : {
            // microService: 0,
            // dockerDeploy: 0,
            versions: [],
          };
    },
  },
  data() {
    return {
      form: {},
      lableDrawer: false,
      labelList: [],
      endList: [],
      versionList: [],
      tableData: [{}],
      tableHeader: [
        {
          code: "categoryCode",
          name: "版本",
        },
        { code: "ownerName", name: "备注" },
      ],
      typeList: [{ name: "jar" }, { name: "war" }, { name: "pom" }],
      scopeList: [
        { name: "import" },
        { name: "compile" },
        { name: "runtime" },
        { name: "test" },
        { name: "system" },
        { name: "provided" },
      ],
      rules: {
        name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        labelId: [{ required: true, message: "请选择标签", trigger: "blur" }],
        groupId: [
          { required: true, message: "请输入公司或组织域名", trigger: "blur" },
        ],
        artifactId: [
          { required: true, message: "请输入实际项目名称", trigger: "blur" },
        ],
        gitlabProjectName: [
          { required: true, message: "请选择工程名称", trigger: "blur" },
        ],
        gitlabProjectUrl: [
          { required: true, message: "请选择工程地址", trigger: "blur" },
        ],
        branch: [
          { required: true, message: "请输入代码分支", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // if (this.form.versions.length == 0) {
          //   this.$msg.error("版本不能为空");
          // } else {}
          this.fetch();
        } else {
          return false;
        }
      });
    },
    async fetch() {
      let params = {
        ...this.form,
        thirdPart: this.thirdPart,
      };
      const fn = this.form.id ? Api.updateRely : Api.addRely;

      let res = await fn(params);
      this.$msg.success("操作成功");
      this.close();
      this.$emit("getData");
    },

    close() {
      this.$emit("close");
    },
    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    addLabel() {
      this.lableDrawer = true;
    },

    labelChange(value) {
      this.labelList.forEach((item) => {
        if (item.id === value) {
          this.form.label = item.label;
        }
      });
    },
    backFrameworkChange(val) {
      this.endList.forEach((item) => {
        if (item.id === val) {
          this.form.backFrameworkName = item.name;
        }
      });
      delete this.form.backFrameworkVersionId;
      this.getVersion(val);
    },
    backFrameworkVersionChange(val) {
      this.versionList.forEach((item) => {
        if (item.backFrameworkVersionId === val) {
          this.form.backFrameworkVersion = item.backFrameworkVersion;
        }
      });
    },

    addTable() {
      this.form.versions.push({
        version: "",
        versionDescription: "",
      });
    },
    delTable(index) {
      this.form.versions.splice(index, 1);
    },

    async getFrameAll() {
      let endRes = await frameworkApi.getEndAll();
      if (endRes.data) {
        this.endList = endRes.data;
      }
    },
    async getVersion(id) {
      let res = await frameworkApi.getVersion(id);
      if (res.data) {
        this.versionList = res.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.inputWidth {
  width: 60%;
}
</style>