
import http from "@/utils/axios";

const DevOpsApi = {
    //基础依赖
    addRely: (data) => http.post("eds/dependency/add", data),
    updateRely: (data) => http.put("eds/dependency/update", data),
    deRely: (data) => http.delete("eds/dependency/delete/"+data),
    getRely: (data) => http.get("eds/dependency/query/page", data),
    getDependency: (data) => http.get("eds/back/framework/query/dependency/version", data),
    //第三方依赖
    addOtherRely: (data) => http.post("eds/third/party/dependency/add", data),
    deOtherRely: (data) => http.delete("eds/third/party/dependency/delete/"+data),
    getOtherRely: (data) => http.get("eds/third/party/dependency/query/page", data),
    updateOtherRely: (data) => http.put("eds/third/party/dependency/update", data),
    // 获取 Maven 常量
    getMavenConstant: (data) => http.get("eds/maven/constant", data),
}
export default DevOpsApi;