
<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="Name">
            <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="Group">
            <el-input placeholder="请输入" v-model="form.groupId" clearable></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input placeholder="请输入" v-model="form.description" clearable></el-input>
          </el-form-item>
          <el-form-item label="标签">
            <el-select v-model="form.label" clearable>
              <el-option v-for="(item, index) in labelList" :key="index" :value="item.label" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleAdd(false)">新增</el-button>
            <el-button type="primary" plain @click="lableDrawer = true">标签管理</el-button>
          </el-form-item>

          <el-tabs v-model="activeState" type="card" @tab-click="getData">
            <el-tab-pane label="启用" name="0"></el-tab-pane>
            <el-tab-pane label="禁用" name="1"></el-tab-pane>
          </el-tabs>
        </el-form>
      </template>
      <template #default>
        <el-table ref="table" :data="tableData" v-loading="tableLoading" border highlight-current-row height="100%">
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column prop="name" label="Name" align="center" />
          <el-table-column prop="groupId" label="Group" align="center" />
          <el-table-column prop="artifactId" label="Artifact" align="center" />
          <el-table-column prop="description" label="Description" align="center" />
          <el-table-column prop="microServiceDesc" label="微服务" align="center" />
          <el-table-column prop="dockerDeployDesc" label="容器部署" align="center" />
          <el-table-column prop="label" label="标签" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDisabled(scope.row)">
                {{scope.row.disabled==0?"禁用":"启用"}}
              </el-button>
              <el-button type="text" @click="handleAdd(true, scope.row)"> 编辑 </el-button>
              <el-button type="text" @click="handleDelete(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.pageSize" :current-page="page.pageNum" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" size="80%" direction="rtl" @close="getData()">
      <lableMgt></lableMgt>
    </el-drawer>

    <add-modal :detail="rowData" :show="addDrawer" :isEdit="isEdit" @getData="getData" @close="addModalClose" :thirdPart="thirdPart"></add-modal>
  </div>
</template>
<script>
import addModal from "./addModal";
import lableMgt from "../../common/labelMgt.vue";
import Api from "@/api/devops/relyMgt";
import commonApi from "@/api/devops/common";
export default {
  components: { addModal, lableMgt },
  data() {
    return {
      form: {
        name: "",
        label: "",
        groupId: "",
      },
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      lableDrawer: false,
      addDrawer: false,
      activeName: "first",
      rowData: {},
      isEdit: true,
      labelList: [],
      thirdPart: 0,
      activeState: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    query() {
      this.getData();
    },

    handleAdd(isEdit, data) {
      if (isEdit) {
        this.rowData = _.cloneDeep(data);
      } else {
        this.rowData = {};
      }
      this.addDrawer = true;
      this.isEdit = isEdit;
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await Api.deRely(data.id);
        this.$msg.success("操作成功");
        this.getData();
      });
    },
    async getData() {
      let params = {
        ...this.form,
        disabled: this.activeState,
        current: this.page.current,
        size: this.page.size,
        thirdPart: this.thirdPart,
      };
      let res = await Api.getRely(params);
      this.tableData = res.data.records;
      this.page.total = res.data.total;
      this.getLabel();
    },
    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    async handleDisabled(data) {
      let disabled = data.disabled == 0 ? 1 : 0;
      let params = {
        ...data,
        thirdPart: this.thirdPart,
        disabled: disabled,
      };
      let res = await Api.updateRely(params);
      this.$msg.success("操作成功");
      this.getData();
    },
    addModalClose() {
      this.addDrawer = false;
    },
  },
};
</script>
